<template>
  <span :class="$style.root">
    <span :class="$style.bar" />
    <span :class="$style.text">
      <slot />
    </span>
  </span>
</template>

<style module>
.root {
  display: flex;
  gap: .35ch;
  align-items: center;
  /* justify-content: space-evenly; */

  line-height: var(--fs, 1);
}

.bar {
  width: var(--stroke--thick);
  height: calc(var(--lh, 1em) );
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
}

.bar:before {
  content: '';
  display: block;
  width: 100%;
  height: calc(100% - .2em);
  background-color: currentColor;
  border-radius: 9999px;

  margin-top: .1em;
}

.text {
  transform: translateY(-.05em);
}
</style>
